.testimonial{
    background-image: url("../../Assets/images/teastmonial.jpg");
}
.about-bg{
    background-image: url("../../Assets/images/About-US.png");
    background-position: 50% 20%;
    background-repeat: no-repeat;
}

.cliieent_1{
    position: absolute !important;
    display: inline-block !important;
}

.client-review{
    display: none !important;
    position: absolute !important;
    width: 25vw;
}

.breadcrumb-area .breadcrumb-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.breadcrumb-area .breadcrumb-list .lotterlo1 {
  position: relative;
  top: 2px;
}
.cliieent_1:hover .client-review{
    background-color: #fff !important;
    border-radius: 30px;
    padding: 10px;
    z-index: 10000;
    text-align: center;
    display: block !important;
    margin-left: -30px;
}
@media screen and (max-width: 767px) {
.about-bg{
    background-image: url("../../Assets/images/mobile-about-us.png");
    background-position: 50% 50%;
    padding: 35px 10px 45px 10px;
}
.breadcrumb-area.about-bg .title {
  margin: 0;
}
.about-counter {
  margin-top: -20px;
}
.testimonial .about-feature{
    top: 0;
}
}
@media screen and (max-width: 700px) {
    .client-review{
        width: 43vw;
    }

    .cliieent_1:hover{
        margin-left: 20px;
    }
}

.list_Guide{
    list-style-type:disc;
    margin-left: 2rem;

}
.list_Guide .subList_Guide li{
    list-style-type:lower-roman !important;
    margin-left: 3rem;
}
.abtimg{
    margin: 0 auto;
}