/*.breadcrumb-area{
    background-image: url("../../Assets/images/breadcrumb.png");
}*/

.breadcrumb-area{
    background-image: url("../../Assets/images/result.png");
    background-position: 50% 20%;
}
@media (max-width: 767px) {
.breadcrumb-area{
    background-image: url("../../Assets/images/mobile-results.png");
    background-position: 50% 50%;
}

}

.result-bg{
/*  background-image: url("../../Assets/images/Result.png");*/
  background-size: cover;
  background-repeat: no-repeat;
}
.result-bg ul.breadcrumb-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.how-to-use-bg{
  background-image: url("../../Assets/images/How-To-use.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.result-bg ul.breadcrumb-list .lotterlo1 {
  position: relative;
  top: 2px;
}
.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .single-number{
    border-radius: 10px;
    box-shadow: 0px 13px 30px 0px rgb(45 70 222 / 20%);
    padding: 22px 30px 30px;
  }

  .uuull_slider{
    justify-content: space-evenly !important;
  }

  .uuull_slider li{
    list-style: none;
    padding: 5px 0;
  }

  .uuull_slider li span{
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-weight: 600;
    background: #ededff;
    background-image: -o-linear-gradient(4deg, #ec038b 0%, #fb6468 44%, #fbb936 100%);
    background-image: linear-gradient(86deg, #ec038b 0%, #fb6468 44%, #fbb936 100%);
    -webkit-box-shadow: 0px 7px 7px 0px rgb(243 42 126 / 23%);
    box-shadow: 0px 7px 7px 0px rgb(243 42 126 / 23%);
    color: #fff;
    border-radius: 50%;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
  }

  .single-number input{
    margin-top: 14px !important;
    border: 1px solid #e5e7ff;
    height: 50px;
    padding: 0px 20px;
    border-radius: 10px;
    color: #5949b3;
    background: transparent;
  }

  .item{
    padding: 20px !important;
  }

  .apna_swiper{
    margin-top: 15px !important;
  }
  /* .lottery{color: #fff !important;} */
  .right-content {
  line-height: 30px;
  font-size: 16px;
}