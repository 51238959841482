.banner-section{
    background-image: url("../../Assets/images/hero-bg.png") !important;
    
}

.swipper_paddding{
    padding: 30px !important;
}

.winnerimg h4 {
    display: inline-flex;
}

.single-draw{
    cursor: pointer;
    transition: all ease 0.3s !important;
}

.single-draw:hover{
    transform: translateY(-9px) !important;
}

.single-draw h4 {
    font-size: 23px;
    line-height: 34px;
    font-weight: 700;
}

.winnerimg h4 img {
    width: 30px !important;
    /* margin-top: -5px; */
}

.text{
    font-family: "Comfortaa", cursive;
}

.paadding{
    padding: 0 0px;
    margin-left: 10px;
}


/* .swiper-button-next{
    color: red !important;
    border: 1px solid red !important;
}
.swiper-button-prev{
    background-color: red !important;
} */

.custom_button_Owner {
    background-image: -o-linear-gradient(83deg, #e83e8c 0%, #6c0092 100%);
    background-image: linear-gradient(7deg, #e83e8c 0%, #6c0092 100%);
    border: 1px solid #6c0092;
    border-radius: 5px;
    -webkit-box-shadow: 0px 3px 2px 0px rgba(32, 29, 30, 0.25);
            box-shadow: 0px 3px 2px 0px rgba(32, 29, 30, 0.25);
    padding: 5px 10px;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    display: inline-block;
    cursor: pointer;
    width: auto;
    height: auto;
  }
  .custom_button_Owner:hover {
    color: #fff;
  }
  .tabun1{
    height: auto !important;
  }

  
  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
.input_cont{
    text-align: center;
    border-radius: 10px;
    height: 2.5rem;
}
.banner-title{
    font-size: 4.0rem !important;
}
.banner-subtitle{
    font-size: 1.4rem !important;
}
.landing_page_text{
    /* font-size: 1.2rem !important; */
    margin-top: -1rem !important;
}
.lottery-result{
    background-image: url("../../Assets/images/result_background.png");
}