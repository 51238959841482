 .faq-bg{
    background-image: url("../../Assets/images/FAQ.png");
 }
 .accordion-body{
    border: 0 solid rgb(219, 216, 216);
    border-radius: 10px;
    font-family: "Comfortaa", cursive;
    color: #000 !important;
   line-height: 30px;
  font-size: 16px;
}

.accordion-flush .accordion-item{
    margin-bottom: 25px;
}

.title{
    margin-bottom: 10px;
}