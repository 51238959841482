.text{
    padding: 30px 10px;
}

.ant-table-row .ant-table-row-level-0{
    padding: 40px 0px !important;
}

.w-5 {
    width: 1.25rem;
}

.h-5 {
    height: 1.25rem;
}

@media (min-width: 1024px){
.lg\:mt-6 {
    margin-top: 1.5rem;
}}

@media (min-width: 640px){
.sm\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}}
@media (min-width: 640px){
.sm\:gap-5 {
    gap: 1.25rem;
}}
@media (min-width: 640px){
.sm\:grid-cols-4 {
    grid-template-columns: repeat(4,minmax(0,1fr));
}}

.grid {
    display: grid;
}

.p-4 {
    padding: 1rem;
}

.bg-slate-100 {
    background-color: #f1f5f9;
}
.rounded-lg {
    border-radius: 0.5rem;
}

.justify-between {
    justify-content: space-between;
}

.flex {
    display: flex;
}

.text-slate-700 {
    color: #334155;
}

.font-semibold {
    font-weight: 600;
}
.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.text-primary {
    color: #4f46e5;
}

.text-xs\+ {
    font-size: .8125rem;
    line-height: 1.125rem;
}

.mt-1 {
    margin-top: 0.25rem;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: white !important;
    border: none !important;
}

tbody, td, tfoot, th, thead, tr, .table-bordered{
    border: none !important;
    padding-top: 27px !important;
    padding-bottom: 27px !important;
}

tr{
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px !important;
}
.table{
    --bs-table-hover-bg: none !important;
    --bs-table-active-bg: none !important;
}

.boxx{
    margin-bottom: 50px !important;
}

@media screen and (max-width:700px) {
    .t_head{
        font-size: 14px !important;
    }

    .boxx{
        font-size: 12px;
    }

    tbody, td, tfoot, th, thead, tr, .table-bordered{
        padding-top: 17px !important;
        padding-bottom: 17px !important;
    }
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-image: linear-gradient(to left, #e44177, #e94a6d, #ed5463, #ee5f59, #ee6a51) !important;

}

 .title_admin {
    font-size: 55px;
    line-height: 65px;
    font-weight: 700;
    margin-bottom: 12px;
    display: block;
    color: #5949b3;
    text-transform: uppercase;
}